<template>
  <div>
    <div id="card-container">
      <template v-for="plan in Plans">
        <template v-for="(duration, index) in plan.PlanDurations">
          <component
            :is="getPlanComponent(plan.PlanId)"
            v-bind:key="index"
            class="card"
            :price="duration.Price"
            :duration="duration.Duration"
            :id="duration.Id"
            @selected="next"
          />
        </template>
      </template>
    </div>
    <div id="button-container">
      <button class="back-button" @click="back">Terug</button>
    </div>
  </div>
</template>

<script>
import Fake from '../plans/Fake';
import Management from '../plans/Management';
import ManagementSecurity from '../plans/ManagementSecurity';
import RideRegistration from '../plans/RideRegistration';
import SCM from '../plans/SCM';
import SCMCombi from '../plans/SCMCombi';
import Tag from '../plans/Tag';
import SCMTotal from '../plans/SCMTotal';
import SCMTag from '../plans/SCMTag';
import Peilzender from '../plans/Peilzender';

export default {
  components: {
    Fake,
    Management,
    ManagementSecurity,
    RideRegistration,
    SCM,
    SCMCombi,
    Tag,
    SCMTotal
  },
  created() {
    this.$emit("changeStep", 2);

    // Demo mode: don't load data we won't be able to find because we don't have an actual activation. Just fake it.
    if (this.$cookies.get("activation-code") === "demo"){
      this.Plans = [{
        PlanId: -1,
        PlanDurations: [
          {Id: 1, Duration: 1, Price: 0},
          {Id: 2, Duration: 6, Price: 0},
          {Id: 3, Duration: 12, Price: 0}
        ]
      }];
      return;
    }

    this.$api.get(`Plan/${process.env.VUE_APP_TITLE}`).then(response => {
      this.Plans = response.data;
      console.log("parsed");
    });
  },
  data() {
    return {
      Plans: null
    };
  },
  methods: {
    back() {
      this.$router.push("/login");
    },
    next(duration) {
      // Demo mode: don't save, just go to the next page.
      if (this.$cookies.get("activation-code") === "demo"){
        this.$router.push("/payment");
        return;
      }

      this.$api.post(`Plan/Save/${duration}`, {}).then(() => {
        this.$router.push("/payment");
      });
    },
    getPlanComponent(id) {
      switch (id) {
        case -1:
          return Fake;
        case 1:
          return Management;
        case 2:
          return ManagementSecurity;
        case 3:
          return SCM;
        case 4:
          return Tag;
        case 5:
          return SCMCombi;
        case 6:
          return RideRegistration;
        case 7:
          return SCMTotal;
        case 9:
          return SCMTag;
        case 1009:
          return Peilzender;
        default:
          return null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
#content-main {
  flex-direction: column;
}

#card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  flex-grow: 1;
  overflow: auto;
}

#button-container {
  padding: 20px 60px;
  display: flex;
  justify-content: center;
}

/* Deep classes for all children of this component */

/deep/ .card {
  display: flex;
  flex-direction: column;
  padding: 25px 60px;
  text-align: center;
}

@media only screen and (max-width: 823px) {
  /deep/ .card {
    padding: 25px;
  }
}

/deep/ .card-title {
  font-size: 24pt;
  font-weight: bold;
  margin-bottom: 5px;
}

/deep/ .month-description {
  font-size: 11pt;
  color: $medium-gray;
}

/deep/ .card-price {
  margin-top: 40px;
}

/deep/ .card-choose {
  background-color: $secondary;
  border: none;
  color: $secondary-button;
  margin-top: 10px;
}

/deep/ .card-choose:hover {
  background-color: $secondary-light;
}

/deep/ .card-description-header {
  font-weight: bold;
  margin: 20px 0 10px 0;
  padding: 5px;
  border-bottom: 1px solid $primary-light;
}

/deep/ .card-descriptions {
  padding: 15px 25px;
}

/deep/ .description-entry {
  margin: 5px 0;
  line-height: 1.71;
}

/deep/ .description-entry::before {
  color: $green;
  content: "✓";
  display: inline-block;
  font-weight: bold;
  letter-spacing: normal;
  margin-right: 8px;
}
</style>