<template>
  <div>
    <span class="card-title">Peilzender</span>
    <p class="month-description">{{duration}} maanden</p>
    <span class="card-price">&euro;{{price}}/jaar</span>
    <button class="card-choose" @click="select()">Bevestig hier uw abonnement</button>
    <div class="card-descriptions">
    </div>
  </div>
</template>

<script>
export default {
    props: ["duration","price", "id"],
    methods: {
        select(){
            this.$emit('selected', this.id);
        }
    }
};
</script>